<template>
    <div style="width: 1500px;min-height: calc(100vh)">
        <!--   时间线   -->
        <div class="head" style="width: 1500px;height: 112px;background: #FFFFFF;">
            <ul class="my_timeline" style="display: flex;justify-content: center;text-align: center">
                <li v-for="(item,index) in timelist" :key="index" class="info">
                    <!--  圆圈节点  -->
                    <div :class="{active: index  <= Index}"  class="primary"> {{ item.num }}</div>
                    <!--  线   -->
                    <div :class="{active: index  <= Index}" class="error"></div>
                    <!--  标注    -->
                    <div  class="dark">{{ item.code }}</div>
                </li>
            </ul>
        </div>
        <div class="head" style="width: 1500px;height: auto;overflow: hidden;background:#FFFFFF;margin-top: 10px;">
                <div v-if="Index == 0" >
                    <div style="height: 24px;font-size: 20px;color: #444444;margin-left:60px;padding-top: 40px;font-weight:bold">请确认当前账号实名信息</div>
                    <!--  基本信息 -->
                    <div style="float: left">
                        <div v-if="user.userType === 1" class="wedo" style="margin-left:60px;">真实姓名:</div>
                        <div v-if="user.userType === 2" class="wedo" style="margin-left:60px;">法人姓名</div>
                        <div class="potname" style="margin-left: 65px;height: 60px;">{{nameauth.realName}}</div>
                    </div>
                    <div style="margin-top:40px;height: 200px">
                        <div style="margin-left:60px;width: 200px;font-size: 20px">身份证号:</div>
                        <div class="potname" style="margin-left: 60px;">{{nameauth.idCardNo}}</div>
                    </div>

                    <div style="width: 1380px;border-bottom: 1px solid #CED0D4;float: left;margin-left: 60px;margin-top: 20px;"></div>
                    <div class="cursor op sc" style="float: right;margin-right: 60px;margin-top: 30px;" @click="inspect(1)">
                        <el-button style="width: 154px;height: 48px;font-size: 18px;background: #2970FF;" type="primary">下一步</el-button>
                        <div style="margin-bottom: 20px;"></div>
                    </div>

                </div>
                <div v-if="Index == 1">
                    <div style="width: 380px;height: 24px;font-size: 20px;color: #707070;margin: 40px 0 0 60px;font-weight:bold">请选择您店铺的主营业务（便捷服务类别）</div>
                    <div style="float: left;margin-top: 20px;">
                        <div v-for="(item,index) in type_list" :key="index">
                            <div @click="changeColor(item)" class="menu hand op sc" :class="[index === type_list.length - 1 ? 'border-bottom' : '']" :style=" currentCategory === item ? 'background:#FFFFFF;color: #2970FF' : ''" style="width: 203px;height: 69px;background: #ECF2FE;margin: 0 0 0 60px;" >
                                <div style="width: 72px;height: 21px;font-size: 18px;margin: 24px 80px;cursor: pointer;font-weight:bold" >{{item.label}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="float: left;margin-top: 20px;">
                        <div v-if="currentCategory !== null">
                             <div style="width: 1169px;height: 589px;background: #FFFFFF;border: 1px solid #CED0D4;">
                            <div style="width: 100%;height: 21px;font-size: 18px;color: #707070;margin: 24px 0 0 61px;font-weight:bold">二级分类（多选，至少选择一项，最多选择20项）</div>
                                 <div v-for="(item2, index2) in currentCategory.children" :key="index2" style="height: auto; background: #FFFFFF; border: 1px solid #CED0D4; margin: 20px 0 0 61px; float: left; border-radius: 5px">
                                     <div style="height: auto; font-size: 16px; cursor: pointer; width: 100%;" class="hand op sc" :class="{ selected: isSelected(item2) }" @click="add_type_list(item2)">
                                         <div style="margin: 0 10px;">{{ item2.label }}</div>
                                     </div>
                                 </div>
                                <div style="width: 1168px;border-top: 1px solid #CED0D4;float: left;margin-top: 10px"></div>
                                <div style="width: auto;color: #444444;height: 21px;font-size: 18px;float: left;margin: 24px 0 0 60px;">当前已选择：<span style="color: #2970FF">{{selectedItems.length}}</span>项</div>
                        </div>
                        </div>
                        <div :style="currentCategory == null ? 'margin:590px 0 0 740px;':''">
                            <div  class="cursor op sc" style="float: right;margin-right: 60px;margin-top: 30px;" @click="addnow(2)">
                                <el-button style="width: 154px;height: 48px;font-size: 18px;background: #2970FF;" type="primary">下一步</el-button>
                                <div style="margin-bottom: 30px;"></div>
                            </div>
                            <div class="cursor op sc" style="float: right;margin-right: 60px;margin-top: 30px;" @click="addnow(0)">
                                <el-button style="width: 154px;height: 48px;font-size: 18px" >上一步</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="Index == 2">
                    <div style="width: 220px;height: 24px;font-size: 20px;color: #707070;margin: 40px 0 0 60px;font-weight:bold">请填写您店铺的详细信息</div>
                    <!-- 店铺名称  -->
                    <div style="width: auto;height: 24px;font-size: 20px;color: #F3443A;margin-left: 60px;padding-top:30px;clear: both;font-weight:bold">*<span style="color: #444444;">店铺名称</span></div>
                    <div style="width: 520px;height:auto;border-radius: 5px 5px 5px 5px;margin: 16px 0 0 60px;">
                        <el-input type="text" placeholder="输入店铺名称" v-model="name" maxlength="30" show-word-limit></el-input>
                    </div>
                    <div style="width: auto;height: 24px;font-size: 20px;color: #F3443A;margin: 20px 0 0 60px;font-weight:bold">*<span style="color: #444444;">店铺经营地址</span></div>
                    <div style="display: flex">
                        <div style="margin: 16px 0 0 60px;">
                            <address-selector @change="selectCity" :address ="city"  :lv="3" :info="true" :multiple="false"></address-selector>
                        </div>
                    </div>
                    <div style="width: auto;height: 24px;font-size: 20px;color: #F3443A;margin: 20px 0 0 60px;font-weight:bold">*<span style="color: #444444;">店铺介绍</span></div>
                    <div style="margin-left: 60px;">
                        <el-input style="width: 766px;margin-top: 25px;" type="textarea" resize="none" :rows="5" placeholder="店铺介绍" v-model="sendContent" :autosize="{ minRows: 5, maxRows: 10 }" :show-word-limit="true" maxlength="300" minlength="10"  />
                    </div>

                    <div v-if="user.userType == 1" style="">
                        <div style="width: auto;height: 24px;font-size: 20px;color: #444444;margin: 20px 0 0 60px;font-weight:bold"><span style="color: red;">*</span>绑定银行卡<span style="color: red;font-size: 14px;">(仅个人)</span></div>
                        <bank-card @banCardList="banCardList"></bank-card>

                    </div>

                    <!--    店铺头像log  -->
                    <div style="width: 100%;height: 24px;font-size: 20px;color: #444444;margin: 40px 0 0 60px;font-weight:bold;float: left"><span style="color: red;">*</span>店铺头像logo<span style="color: red;font-size: 14px;">(单个图片)</span></div>
                    <div style="margin: 12px 0 0 60px;float: left">
                        <el-upload  accept="image/*" style="margin-left: 10px;"  ref="imgList" class="upload-demo" action="" drag multiple :http-request="upload_img" :limit=1 :show-file-list="false" :on-exceed="filelist_num">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" >将图片拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持jpg、png、bmp等图片格式，大小不超过500KB</div>
                        </el-upload>
                    </div>
                    <!--  店铺头像log的展示  -->
                    <div style="flex-wrap:wrap;margin: 8px;width: auto;float: left;">
                        <div style="float: left;width: calc(100% - 300px);height: auto;">
                            <div  class="demo-image__placeholder">
                                <div class="block">
                                    <div v-show="imgs.img_url !== undefined" style="position: relative;float: left;">
                                        <div @click="removeImg()" class="hand op sc" style="position: absolute;left: 183px;top: 5px;z-index: 1;"><i class="el-icon-delete" style="color:#fb0202;font-size:20px;font-weight:bold;"></i></div>
                                        <el-image @click="loadimg(imgs)" style="width: 200px; height: 180px;padding: 5px;object-fit: cover;" :src="imgs.img_url"></el-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <el-dialog :close-on-click-modal="false" :visible.sync="dialogimg">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <div style="width: auto;height: 24px;font-size: 20px;color: #444444;clear: both;margin-left:60px;padding-top: 40px;font-weight:bold"><span style="color: red;">*</span>店铺店招图<span style="color: red;font-size: 14px;">(单个图片)</span></div>
                    <!--    上传背景  -->
                    <div style="margin: 12px 0 0 60px;float: left">
                        <el-upload  accept="image/*" style="margin-left: 10px;" ref="urlimglist"  class="upload-demo" action="" drag multiple :http-request="upload_url_img" :limit=1 :show-file-list="false" :on-exceed="filelist_num">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" >将背景拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持jpg、png、bmp等背景格式，大小不超过500KB</div>
                        </el-upload>
                    </div>
                    <!--  上传背景图的展示  -->
                    <div style="display:flex;width:1000px;flex-wrap:wrap;margin: 8px">
                        <div style="float: left;width: calc(100% - 300px);height: auto;">
                            <div  class="demo-image__placeholder">
                                <div class="block">
                                    <div v-show="imgs_url.img_url !== undefined" style="position: relative;float: left;">
                                        <div @click="removeImg2()" class="hand op sc" style="position: absolute;left: 183px;top: 5px;z-index: 1;"><i class="el-icon-delete" style="color:#fb0202;font-size:20px;font-weight:bold;"></i></div>
                                        <el-image @click="loadimg(imgs_url)" style="width: 200px; height: 180px;padding: 5px;object-fit: cover;" :src="imgs_url.img_url"></el-image>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: auto;height: 24px;font-size: 20px;color: #444444;clear: both;margin-left:60px;padding-top: 40px;font-weight:bold"><span style="color: red;">*</span>店铺轮播图</div>
                    <!--    上传轮播图  -->
                    <div style="margin: 12px 0 0 60px;float: left">
                        <el-upload  accept="image/*" style="margin-left: 10px;"  ref="img_imgslist" class="upload-demo" action="" drag multiple :http-request="upload_img_imgs" :limit=80 :show-file-list="false" :on-exceed="filelist_num">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" >将图片拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持jpg、png、bmp等图片格式，大小不超过500KB</div>
                        </el-upload>
                    </div>
                    <!--  上传轮播图的展示  -->
                    <div style="width:auto;margin: 8px;display:flex;flex-wrap:wrap;">
                        <div  class="demo-image__placeholder">
                            <div class="block">
                                <div style="position: relative;float: left;" v-for="(item,index) in imgs_url_img" :key="index">
                                    <div @click="removeImg3(index)" class="hand op sc" style="position: absolute;left: 183px;top: 5px;z-index: 1;"><i class="el-icon-delete" style="color:#fb0202;font-size:20px;font-weight:bold;"></i></div>
                                    <el-image  @click="loadimg(item)" style="width: 200px; height: 180px;padding: 5px;object-fit: cover;" :src="item.img_url"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="width: auto;height: 24px;font-size: 20px;color: #444444;clear: both;margin-left:60px;padding-top: 40px;font-weight:bold"><span style="color: red;">*</span>产品图片</div>
                    <!--    店铺图片  -->
                    <div style="margin: 12px 0 0 60px;float: left">
                        <el-upload  accept="image/*" style="margin-left: 10px;"  ref="imgsshoplist" class="upload-demo" action="" drag multiple :http-request="upload_img_imgs_shop" :limit=80 :show-file-list="false" :on-exceed="filelist_num">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" >将图片拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持jpg、png、bmp等图片格式，大小不超过500KB</div>
                        </el-upload>
                    </div>
                    <!--  店铺图片的展示  -->
                    <div style="display:flex;width:auto;flex-wrap:wrap;margin: 8px">
                        <div  class="demo-image__placeholder">
                            <div class="block">
                                <div style="position: relative;float: left;" v-for="(type,index) in imgs_url_img_shop" :key="index" >
                                    <div @click="removeImg4(index)" class="hand op sc" style="position: absolute;left: 183px;top: 5px;z-index: 1;"><i class="el-icon-delete" style="color:#fb0202;font-size:20px;font-weight:bold;"></i></div>
                                    <el-image @click="loadimg(type)" style="width: 200px; height: 180px;padding: 5px;object-fit: cover;" :src="type.img_url"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  店铺推荐图  -->
                    <div style="width: auto;height: 24px;font-size: 20px;color: #444444;clear: both;margin-left:60px;padding-top: 40px;font-weight:bold"><span style="color: red;">*</span>店铺封面<span style="color: red;font-size: 14px;">(单个图片)</span></div>
                    <!-- 上传店铺推荐图 -->
                    <div style="margin: 12px 0 0 60px;float: left">
                        <el-upload  accept="image/*" style="margin-left: 10px;"  ref="Recommendedlist" class="upload-demo" action="" drag multiple :http-request="Recommended_img_imgs_shop" :limit=80 :show-file-list="false" :on-exceed="filelist_num">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" >将图片拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">支持jpg、png、bmp等图片格式，大小不超过500KB</div>
                        </el-upload>
                    </div>
                    <!--  店铺图片的展示  -->
                    <div style="display:flex;width:1000px;flex-wrap:wrap;margin: 8px">
                        <div  class="demo-image__placeholder">
                            <div class="block">
                                <div v-show="Recommended_imgs_url_img_shop.img_url !== undefined" style="position: relative;float: left;">
                                    <div @click="removeImg5()" class="hand op sc" style="position: absolute;left: 183px;top: 5px;z-index: 1;"><i class="el-icon-delete" style="color:#fb0202;font-size:20px;font-weight:bold;"></i></div>
                                    <el-image @click="loadimg(Recommended_imgs_url_img_shop)" style="width: 200px; height: 180px;padding: 5px;object-fit: cover;" :src="Recommended_imgs_url_img_shop.img_url"></el-image>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="width: 1380px;float: left;margin-left: 60px;margin-top: 30px;"></div>
                    <div style="float:left;width:1000px;flex-wrap:wrap;margin: 8px">
                        <div v-if="user.userType === 1" style="width: 80px;height: 24px;font-size: 20px;color: #444444;margin-left: 60px;">联系方式</div>
                        <div v-if="user.userType === 2" style="width: 130px;height: 24px;font-size: 20px;color: #444444;margin-left: 60px;">法人联系方式</div>
                        <div style="width: 410px;height: 60px;margin: 16px 0 0 60px;float: left;">
                            <el-input  oninput="if(value.length>11)value=value.slice(0,11)" v-model.trim="reserve" @change="changePhone" name="reserve" :validate-event=true  placeholder="请输入手机号" class="input-with-select" @input="UpNumber">
                                <template slot="prepend">+86</template>
                            </el-input>
                        </div>
                        <div style="float: left;">
                            <div style="width: 399px;height: 60px;margin:16px 0 0 30px">
                                <el-input  oninput="if(value.length>6)value=value.slice(0,6)" v-model.trim="code" @change="change" name="code" :validate-event=true  placeholder="请输入短信验证码" @input="UpNumber">
                                    <template slot="append">
                                        <el-button type="primary" class="hand op sc" style="width: 100%;height: 30px;border: 0; cursor: pointer;color: #000000;"  ref="codediv" :disabled="disabled" @click="acquireCode">{{ codestring }}</el-button>
                                    </template>
                                </el-input>
                            </div>
                        </div>
                    </div>


                    <div style="width: 1380px;border-bottom: 1px solid #CED0D4;float: left;margin-left: 60px;margin-top: 20px;"></div>
                    <div class="cursor" style="float: right;margin-right: 40px;margin-top: 30px;" @click="addnow(3)">
                        <el-button style="width: 154px;height: 48px;font-size: 18px;background: #2970FF;" type="primary">下一步</el-button>
                        <div style="margin-bottom: 30px;"></div>
                    </div>
                    <div class="cursor" style="float: right;margin-right: 40px;margin-top: 30px;" @click="addnow(1)">
                        <el-button style="width: 154px;height: 48px;font-size: 18px" >上一步</el-button>
                    </div>
                    <div class="cursor" style="float: right;margin-right: 40px;margin-top: 30px;" @click="saveDraft()">
                        <el-button type="success" style="width: 154px;height: 48px;font-size: 18px;" >保存</el-button>
                    </div>
                    <div style="margin-bottom: 20px;"></div>
                </div>
                <div v-if="Index == 3">
                    <div style="display: flex;justify-content: center;align-content: center;margin-top: 240px">
                        <svg t="1681969025091" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3630" width="80" height="80"><path d="M512 0C229.003636 0 0 229.003636 0 512s229.003636 512 512 512 512-229.003636 512-512S794.996364 0 512 0z m260.654545 425.425455l-279.272727 279.272727c-5.585455 5.585455-13.032727 9.309091-21.410909 9.309091-10.24 1.861818-20.48-0.930909-27.927273-8.378182l-175.941818-176.872727a30.906182 30.906182 0 0 1 0-43.752728l14.894546-14.894545c12.101818-12.101818 31.650909-12.101818 43.752727 0l141.498182 141.498182 244.829091-244.829091c12.101818-12.101818 31.650909-12.101818 43.752727 0L772.654545 381.672727c12.101818 12.101818 12.101818 31.650909 0 43.752728z" fill="#65B85D" p-id="3631"></path></svg>
                    </div>
                    <div style="display: flex;justify-content: center;align-content: center;text-align: center;line-height: 55px;">
                        <div style="width: 384px;height: 28px;font-size: 24px;color: #444444;font-weight:bold;">恭喜您创建完毕,等待审核</div>
                    </div>
                    <div style="display: flex;justify-content: center;align-content: center;margin-top: 30px;" @click="enter">
                        <el-button type="primary">返回首页</el-button>
                    </div>
                </div>
                <div style="margin-bottom: 10px;"></div>
            </div>
    </div>
</template>

<script>

import AddressSelector from "@/components/common/city/AddressSelect.vue";
import bankCard from "@/components/common/Pay/bankCard";

export default {
    components: {
	      AddressSelector,
        bankCard
    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            nameauth:{},
            codestring:'获取验证码',
            reserve:"",
            code:"",
            disabled:false,
            Index:0,
            timelist:[
                {
                    code: '确认账号实名信息',
                    num:'01',
                }, {
                    code: '选择店铺主营业务',
                    num:'02',
                }, {
                    code: '填写店铺详细信息',
                    num:'03',
                }, {
                    code: '完成店铺创建',
                    num:'04',
                }
            ],
            imgs:{},
            imgs_url:{},
            imgs_url_img:[],
            imgs_url_img_shop:[],
            Recommended_imgs_url_img_shop:{},
            removeImgs3:[],
            removeImgs4:[],
            name:'',
	        city: {
		        cityId : [],
		        cityName : [],
		        address:""
	        },
            now_id:"",
            sendContent: '',
            address:"",
            code_type:"",
            type_list:[],
            currentCategory: null,
            selectedItems: [{"label":"旅游策划","value":16}],
            selectedProvince: null,
            countdownNum:60,
            dialogimg:false,
            dialogImageUrl: '',
            openBankCard:false,
            bancardConut:0.
        }
    },
    mounted:function(){
        var that = this;
        that.getUserReadNameAuth();
        that.getTypeLists();
        that.getShopDrafts();
    },
    methods:{
        // 处理子组件传递过来的值
        banCardList(value) {
            this.bancardConut = value;
        },
        loadimg(item){
            this.dialogimg = true;
            this.dialogImageUrl = item.img_url;
        },
        UpNumber() {
            this.reserve = this.reserve.replace(/[^0-9]/g, "");
            this.code = this.code.replace(/[^0-9]/g, "");
        },
	    selectCity(value) {
		    this.address = value;
	    },
        // 获取验证码
        acquireCode(){
            var that = this;
            if (that.reserve == ""){
                that.utils.err('请输入手机号');return
            }
            this.newApi.sendSms({
                mobile:that.reserve,
                smsType: 9
            }).then((ret)=>{
                if(ret.isSuccess === 1){
                    that.utils.sus(ret.data);
                    that.changecode();
                }else {
                    that.utils.err('验证码发送失败');
                }
            })
        },
        // 根据选中的一级分类值，获取对应的二级分类数据
        // handleProvinceChange() {
        //     this.selectedProvince = this.typeList.find(item => item.id === this.city_id);
        //     this.code_type = '';
        // },
        add_type_list(value){
            
            if (this.isSelected(value)) {
                this.selectedItems.splice(this.selectedItems.indexOf(value), 1);
            } else {
                if (this.selectedItems.length < 20) {
	                delete value.children
                    this.selectedItems.push(value);
                } else {
                   this.utils.err("最多只能添加20个")
                }
            }
        },
        isSelected(index) {
	        return this.selectedItems.findIndex(item => item.value === index.value) !== -1
        },
        changeColor(item) {
            this.currentCategory = item;
        },
        getUserReadNameAuth(){
            var that = this;
            that.newApi.getUserReadNameAuth({
                lv: 1,
            }).then((res) => {
                that.nameauth = res.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getTypeLists(){
            var that = this;
            that.newApi.getTypeList({
                lv: 3,
            }).then((res)=>{
                that.type_list = res.data;
	            that.currentCategory = that.type_list[0];
            }).catch((err)=>{
                console.log(err)
            })
        },
        changePhone(){
            var re = /^1[3|5|6|7|8|9][0-9]\d{8}$/;
            if (this.reserve=='' || !re.test(this.reserve)){
                this.utils.err("手机号正确")
            }
        },
        // 验证码倒计时60方法
        changecode(){
            var that = this;
            if(that.countdownNum == 0) {
                that.disabled = false;
                that.codestring ='获取验证码';
                that.countdownNum = 60;
                return;
            } else {
                that.disabled = true;
                that.codestring = that.countdownNum + '秒后重新发送';
                that.countdownNum--;
            }
            setTimeout(function(){
                that.changecode();
            }, 1000)

        },
        change(){
            var that = this;
            if (that.code=='' || that.code.length < 6){
                that.utils.err("验证码不可为空")
            }
        },
        inspect(index){
            console.log(index);
            this.addnow(index);
        },

        getShopDrafts() {
            var that = this;
            that.newApi.getShopDrafts({}).then((res) =>{
                console.log(res.data);
                if(res.isSuccess == 1){
                    if(res.data != null){
                        let fields = res.data.fields;
                        that.Index = fields.index;
                        that.imgs = fields.imgs;
                        that.logo = fields.logo;
                        that.name = fields.name;
                        that.imgs_url = fields.imgs_url;
                        that.imgs_url_img = fields.imgs_url_img;
                        that.imgs_url_img_shop = fields.imgs_url_img_shop;
                        that.Recommended_imgs_url_img_shop = fields.Recommended_imgs_url_img_shop;
                        that.city = fields.city;
                        that.address = fields.city.address;
                        that.sendContent = fields.sendContent;
                        that.reserve = fields.phone;
                    }
                }
            })
        },

        //保存草稿
        saveDraft(){
            var that = this;
            var params = {};
            params.index = that.Index;
            if(that.user.userType == 1 ){
                params.type = 1
            }else if (that.user.userType == 2){
                params.type = 2
            }
            params.imgs = that.imgs;
            params.logo =  that.imgs;
            params.name = that.name;
            // 上传背景图
            params.imgs_url = that.imgs_url;
            params.backgroundImg = that.imgs_url;
            // 上传轮播图
            params.imgs_url_img = that.imgs_url_img;
            params.banner = that.imgs_url_img;
            // 上传产品图片
            params.imgs_url_img_shop = that.imgs_url_img_shop;
            params.productImages = that.imgs_url_img_shop;
            // 上传推荐图片
            params.Recommended_imgs_url_img_shop = that.Recommended_imgs_url_img_shop;
            params.cover = that.Recommended_imgs_url_img_shop;
            params.city = that.city;
            params.address = that.city.address;
            params.sendContent = that.sendContent;
            params.introduce = that.sendContent;
            params.phone = that.reserve;
            params.business = that.selectedItems;
            var obj = {};
            obj.fields = JSON.stringify(params)
            that.newApi.addShopDrafts(obj).then((res) => {
                if(res.isSuccess == 1){
                    that.utils.sus(res.data);
                }
            })

        },
        // 添加店铺
        addnow(index){
            var that = this;
            var params = {};
            console.log(index)
            if(index < 3){
                that.Index = index;
            }

            if(index == 2){
                if (that.selectedItems.length == 0){
                    that.utils.err('至少选择一种分类吧');
                    return false
                }
            }

            if(index == 3){
                if(that.user.userType == 1 ){
                    params.type = 1
                }else if (that.user.userType == 2){
                    params.type = 2
                }
                if (that.imgs == ""){
                    that.utils.err('请上传logo');return false
                }
                params.logo =  JSON.stringify(that.imgs);
                if(that.name == ""){
                    that.utils.err('请填写店名称'); return false
                }
                // 上传背景图
                if (that.imgs_url == ""){
                    that.utils.err('请上传背景图');return false
                }
                params.backgroundImg = JSON.stringify(that.imgs_url);
                // 上传轮播图
                if (that.imgs_url_img == ""){
                    that.utils.err('请上传轮播图');return false
                }
                params.banner = JSON.stringify(that.imgs_url_img);
                // 上传产品图片
                if (that.imgs_url_img_shop == ""){
                    that.utils.err('请上传产品图片');return false
                }
                params.productImages = JSON.stringify(that.imgs_url_img_shop);
                // 上传推荐图片
                if (that.Recommended_imgs_url_img_shop == ""){
                    that.utils.err('请上传店铺推荐图片');return false
                }
                params.cover = JSON.stringify(that.Recommended_imgs_url_img_shop);
                params.name = that.name;
	            if (that.city.cityId.length===0){
		            that.utils.err('请选择店铺所在城市'); return false
	            }
                params.city = JSON.stringify(that.city);
                if (that.city.address == ""){
                    that.utils.err('请填写详细地址'); return false;
                }
                params.address = that.city.address;

                if(that.sendContent == ""){
                    that.utils.err('请填写店铺介绍'); return false;
                }
                if(that.user.userType == 1){
                  if(that.bancardConut == 0){
                    that.utils.err('请先绑定银行卡'); return false;
                  }
                }

                var re = /^1[3|5|6|7|8|9][0-9]\d{8}$/;
                if( !re.test(this.reserve)){
                    that.utils.err('手机号不正确');
                    return false;
                }
                if(that.reserve=='' ){
                    that.utils.err('手机号不能为空');
                    return false;
                }
                if(that.code=='' || that.code.length < 6 ) {
                    that.utils.err('验证码不正确');
                    return false;
                }
                params.introduce = that.sendContent;
                params.phone = that.reserve;
                params.business = JSON.stringify(that.selectedItems);
                params.index = index;

                that.newApi.checkShopPhone({phone:that.reserve, code:that.code,}).then((ret)=>{
                    if (ret.isSuccess === 1) {
                        that.openLoading('正在创建请稍后');
                        that.newApi.addShop(params).then((ret)=>{
                            if(ret.isSuccess === 1){
                                that.Index = index;
                                that.utils.sus(ret.data);
                            }
                        })
                    }
                })

            }
        },
        // 上传log
        upload_img:function(params) {
            this.img_length++;
            if (this.img_length > 80) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file, function (url) {
                    if (!url) {
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.img_url = url.url;
                    params.hash = url.hash;
                    that.imgs = params;
                })
            } else {
                this.$message.error('只能上传图片！')
                return false
            }
        },
        // 上传背景图
        upload_url_img:function(params){
            this.img_length++;
            if (this.img_length > 80) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file, function (url) {
                    if (!url) {
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.img_url = url.url;
                    params.hash = url.hash;
                    that.imgs_url = params;
                })
            } else {
                this.$message.error('只能上传图片！')
                return false
            }
        },
        // 上传轮播图
        upload_img_imgs:function(params){
            this.img_length++;
            if (this.img_length > 80) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file, function (url) {
                    if (!url) {
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.img_url = url.url;
                    params.hash = url.hash;
                    that.imgs_url_img.push(params);
                })
            } else {
                this.$message.error('只能上传图片！')
                return false
            }
        },
        // 上传店铺产品
        upload_img_imgs_shop:function(params){
            this.img_length++;
            if (this.img_length > 80) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file, function (url) {
                    if (!url) {
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.img_url = url.url;
                    params.hash = url.hash;
                    that.imgs_url_img_shop.push(params);
                })
            } else {
                this.$message.error('只能上传图片！')
                return false
            }
        },
        Recommended_img_imgs_shop:function (params){
            this.img_length++;
            if (this.img_length > 80) {
                this.$message.error('图片数量达到最大数量！');
                return false;
            }
            var that = this;
            var file = params.file;
            if (file.type.indexOf('image') > -1) {
                that.utils.upload(file, function (url) {
                    if (!url) {
                        return false;
                    }
                    let params = {};
                    params.name = url.name;
                    params.img_url = url.url;
                    params.hash = url.hash;
                    that.Recommended_imgs_url_img_shop = params;
                })
            } else {
                this.$message.error('只能上传图片！')
                return false
            }
        },
        filelist_num:function (files) {
            if (files.length>this.limit) {
                this.utils.error('图片数量不能超过80张!');
            }
        },
        // 删除log
        removeImg:function(){
            var that = this;
                that.utils.confirm("移除这个图片？",function(){
                that.$refs['imgList'].clearFiles();
                that.imgs = {};
            })
        },
        // 删除背景图
        removeImg2:function(){
            var that = this;
            that.utils.confirm("移除这个店招图？",function(){
                that.$refs['urlimglist'].clearFiles();
                that.imgs_url = {};
            })
        },
        // 删除轮播图
        removeImg3:function(index){
            var that = this;
            that.utils.confirm("移除这个轮播图？",function(){
                that.removeImgs3.push(that.imgs_url_img[index]);
                console.log(that.spaceSize,that.imgs_url_img[index].size );
                that.spaceSize = that.spaceSize - that.imgs_url_img[index].size;
                console.log(that.spaceSize );
                that.$refs['img_imgslist'].clearFiles();
                that.imgs_url_img.splice(index,1);
            })
        },
        // 删除产品图片
        removeImg4:function(index){
            var that = this;
            that.utils.confirm("移除这个产品图片？",function(){
                that.removeImgs4.push(that.imgs_url_img_shop[index]);
                console.log(that.spaceSize,that.imgs_url_img_shop[index].size );
                that.spaceSize = that.spaceSize - that.imgs_url_img_shop[index].size;
                console.log(that.spaceSize );
                that.$refs['imgsshoplist'].clearFiles();
                that.imgs_url_img_shop.splice(index,1);
            })
        },
        // 删除店铺推荐图
        removeImg5:function(){
            var that = this;
            that.utils.confirm("移除这个店铺推荐图片？",function(){
                that.$refs['Recommendedlist'].clearFiles();
                that.Recommended_imgs_url_img_shop = {};
            })
        },

        // 返回店铺事件
        enter(){
            var that = this;
            that.$router.push("/")
        },


    }
}
</script>

<style scoped>
.head {
    margin-left: 200px;
}
.wedo{
    width: auto;
    height: 24px;
    font-size: 20px;
    color: #444444;
    padding-top: 40px;
}
.potname {
    width:200px;
    font-size: 18px;
    color: #444444;
    margin-top: 5px;
}
.primary {
    box-sizing: border-box;
    border-radius: 50%;
    cursor: pointer;
    color: #fff;
    background: #E4E7ED;
    width: 28px;
    height: 28px;
    font-size: 15px;
    margin: auto;
    line-height: 28px;
}
.primary.active {
    background-color: #2970FF !important;
    border-radius: 50%;
}
.error.active{
    border-top: 10px solid #2970FF;
}
.error {
    width: 100%;
    height: 10px;
    margin-top: -18px;
    border-top: 10px solid #E4E7ED;
}
.dark {
    margin-top: 10px;
    color: #444444;
    font-size: 18px;
    margin-left: 30px;
}
.info {
    display: inline-block;
    width: 320px;
    padding-top: 23px;
}
.my_timeline {
    width: 100%;
    height: 63px;
    margin: 10px 0;
}
.cursor {
    cursor:pointer;
}
.selected {
    background-color: #2970FF;
    color: #FFFFFF;
    border-radius: 5px
}
.menu {
    border: 1px solid #CED0D4;
    border-bottom: none;
    border-right: none;
}
.menu.border-bottom {
    border-bottom: 1px solid #D9D9D9;
}
/deep/  .el-input-group__append {
    background: #2970FF;
    color: #FFFFFF;
}
/deep/ .el-image__inner {
    border-radius: 8px;
}
/deep/.el-input-group__append{
    background: none;
}

</style>
